@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap);
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}

@media only screen and (min-width: 1440px) {
    .app {
        height: 100vh;
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}

@media only screen and (min-width: 1920px) {
    .app {
        height: 100vh;
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .app {
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}

@media only screen and (min-width: 1440px) {
    h1 {
        font-size: 55px;
        font-weight: 700;
    }

    h5 {
        font-size: 20px;
        font-weight: 100;
        padding: 5px 0;
    }

    p {
        font-size: 16px;
        padding-top: 5px;
        line-height: 28px;
        font-weight: 300;
    }

    .content {
        width: 1440px;
        display: flex;
    }

    .skills {
        font-size: 15px;
        font-weight: 200;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 120px;
        padding-top: 160px;
    }

    .about-me p {
        width: 645px;
    }

    .about-me-img {
        width: 396px;
        padding-top: 50px;
    }

    img {
        width: 100%;
    }

    .btn {
        position: absolute;
        bottom: 200px;
        left: 118px;
        width: 500px;
    }

    .btn-mobile {
        display: none;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 120px;
        padding-top: 160px;
    }

    .contact-h5 {
        padding: 25px 0;
    }

    .contact a {
        font-size: 20px;
        font-weight: 100;
        text-decoration: none;
        color: #564f48;
    }
}

@media only screen and (min-width: 1920px) {
    h1 {
        font-size: 60px;
        font-weight: 700;
    }

    h5 {
        font-size: 22px;
        font-weight: 100;
        padding: 5px 0;
    }

    p {
        font-size: 18px;
        padding-top: 5px;
        line-height: 28px;
        font-weight: 300;
    }

    .content {
        width: 1920px;
        display: flex;
    }

    .skills {
        font-size: 17px;
        font-weight: 200;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 280px;
        padding-top: 270px;
    }

    .about-me p {
        width: 645px;
    }

    .about-me-img {
        width: 528px;
        padding-top: 50px;
        padding-left: 80px;
    }

    img {
        width: 100%;
    }

    .btn {
        position: absolute;
        bottom: 240px;
        left: 275px;
        width: 500px;
    }

    .btn-mobile {
        display: none;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 280px;
        padding-top: 270px;
    }

    .contact-h5 {
        font-size: 22px;
        font-weight: 100;
        padding: 25px 0;
    }
}

@media only screen and (max-width: 500px) {
    h1 {
        padding-left: 16px;
        padding-top: 20px;
        font-size: 30px;
        font-weight: 700;
    }

    h5 {
        font-size: 18px;
        font-weight: 100;
        padding: 10px 16px 10px;
    }

    p {
        font-size: 16px;
        padding: 0px 16px;
        line-height: 28px;
        font-weight: 300;
    }

    .skills {
        font-size: 14px;
        font-weight: 200;
        padding-bottom: 10px;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 100%;
        padding-top: 40px;
        margin-bottom: 40px;
    }

    .about-me-img {
        width: 75.8%;
    }

    img {
        width: 100%;
    }

    .btn {
        display: none;
    }

    .btn-mobile {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 16px;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 3% 8.7%;
        margin-right: 2%;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 2.8% 10.4%;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 414px;
        padding-top: 40px;
        margin-bottom: 40px;
    }

    .contact-h5 {
        padding: 30px 0px 10px 20px;
    }

    .contact a {
        font-size: 20px;
        font-weight: 100;
        text-decoration: none;
        color: #564f48;
    }
}

@media only screen and (min-width: 1440px) {
    .nav {
        width: 100%;
        height: 75px;
        display: flex;
        /* border-bottom: 0.5px solid #e6e6e6; */
    }

    .nav-mobile {
        display: none;
    }

    ul {
        margin: 0;
        padding-right: 130px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: white;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }

    li {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1920px) {
    .nav {
        width: 100%;
        height: 75px;
        display: flex;
        /* border-bottom: 0.5px solid #e6e6e6; */
    }

    .nav-mobile {
        display: none;
    }

    ul {
        margin: 0;
        padding-right: 266px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: white;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }

    li {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 300;
    }
}

@media only screen and (max-width: 500px) {
    .nav {
        display: none;
    }
    .nav-mobile {
        height: 50px;
    }

    .nav-links {
        display: none;
    }

    .nav-links.active {
        margin: 0;
        padding-right: 130px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: #564f48;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .content {
        width: 1440px;
        display: flex;
    }

    .project-links-list {
        height: 452px;
        width: 5px;
        position: absolute;
        left: 65px;
        bottom: 155px;
    }

    .project-ul {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        padding: 0;
    }

    .project-links,
    .project2-links {
        height: 140px;
        opacity: 0.4;
        width: 100%;
        background: #969696;
    }

    .project-links.active,
    .project2-links.active {
        background: #ffffff;
        border: none;
        opacity: 0.9;
    }

    .project-info {
        color: white;
        font-family: "Roboto", sans-serif;
        width: 720px;
        padding-left: 120px;
        padding-top: 70px;
    }

    .project-info h5 {
        font-size: 20px;
        font-weight: 100;
        padding: 5px 0 10px;
    }

    .project-info p {
        width: 645px;
    }

    .role-info {
        padding-top: 20px;
    }

    .role-info h5 {
        font-size: 16px;
        font-weight: 600;
        padding: 5px 0 0;
    }

    .role-info p {
        font-weight: 600;
        font-size: 14px;
    }

    .project-img {
        width: 497px;
        padding-top: 90px;
    }

    .project-img-GH {
        width: 497px;
        padding-top: 90px;
    }

    .project-img img {
        width: 100%;
    }

    .btn2 {
        position: absolute;
        bottom: 170px;
        left: 118px;
        width: 500px;
    }

    .btn2-mobile {
        display: none;
    }

    .btn-links-project {
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #ffffff;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links-project {
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #ffffff;
    }
}

@media only screen and (min-width: 1920px) {
    .content {
        width: 1920px;
        display: flex;
    }

    .project-links-list {
        height: 452px;
        width: 5px;
        position: absolute;
        left: 180px;
        bottom: 275px;
    }

    .project-ul {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
    }

    .project-links,
    .project2-links {
        height: 140px;
        width: 100%;
        opacity: 0.4;
        background: #969696;
    }

    .project-links.active,
    .project2-links.active {
        background: #ffffff;
        border: none;
        opacity: 0.9;
    }

    .project-info {
        color: white;
        font-family: "Roboto", sans-serif;
        height: 400px;
        width: 720px;
        padding-left: 280px;
        padding-top: 133px;
    }

    .project-info h5 {
        font-size: 20px;
        font-weight: 100;
        padding: 5px 0 10px;
    }

    .project-info p {
        width: 645px;
    }

    .role-info {
        padding-top: 20px;
    }

    .role-info h5 {
        font-size: 16px;
        font-weight: 600;
    }

    .role-info p {
        padding: 0;
        font-weight: 600;
    }

    .project-img {
        width: 633px;
        padding-top: 90px;
        padding-left: 20px;
    }

    .project-img-GH {
        width: 633px;
        padding-top: 90px;
        padding-left: 20px;
    }

    .project-img img {
        width: 100%;
    }

    .btn2 {
        position: absolute;
        bottom: 289px;
        left: 280px;
        width: 500px;
    }

    .btn2-mobile {
        display: none;
    }

    .btn-links-project {
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #ffffff;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links-project {
        color: #ffffff;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #ffffff;
    }
}

@media only screen and (max-width: 500px) {
    hr {
        display: none;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .project-links-list {
        height: 282px;
        width: 5px;
        position: absolute;
        left: 16px;
        bottom: 388px;
    }

    .project-ul {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
    }

    .project-links,
    .project2-links {
        height: 85px;
        width: 100%;
        opacity: 0.4;
        background: #969696;
    }

    .project-links.active,
    .project2-links.active {
        background: #ffffff;
        border: none;
        opacity: 0.9;
    }

    .project-info {
        color: white;
        font-family: "Roboto", sans-serif;
        width: 100%;
        padding-top: 40px;
    }

    .project-info h5 {
        font-size: 18px;
        font-weight: 100;
        padding: 10px 16px 10px;
    }

    .project-info p {
        font-size: 16px;
        padding: 0px 16px;
        line-height: 28px;
        font-weight: 300;
    }

    .role-info {
        padding-top: 20px;
        margin-bottom: 40px;
    }

    .role-info h5 {
        font-size: 16px;
        font-weight: 600;
    }

    .role-info p {
        font-size: 16px;
        padding: 0px 16px;
        line-height: 28px;
        font-weight: 600;
    }

    .project-img {
        width: 284px;
    }

    .project-img img {
        width: 100%;
    }

    .project-img-GH {
        width: 330px;
        padding-top: 10px;
    }

    .btn2 {
        display: none;
    }

    .btn2-mobile {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 16px;
    }

    .btn-links-project {
        color: white;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #ffffff;
        padding: 3% 8.7%;
        margin-right: 2%;
        border-radius: 20px;
    }

    .btn2-links-project {
        color: white;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 2.8% 10.4%;
        border-radius: 20px;
        border: 1px solid #ffffff;
    }
}

