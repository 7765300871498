@media only screen and (min-width: 1440px) {
    .nav {
        width: 100%;
        height: 75px;
        display: flex;
        /* border-bottom: 0.5px solid #e6e6e6; */
    }

    .nav-mobile {
        display: none;
    }

    ul {
        margin: 0;
        padding-right: 130px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: white;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }

    li {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1920px) {
    .nav {
        width: 100%;
        height: 75px;
        display: flex;
        /* border-bottom: 0.5px solid #e6e6e6; */
    }

    .nav-mobile {
        display: none;
    }

    ul {
        margin: 0;
        padding-right: 266px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: white;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }

    li {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 300;
    }
}

@media only screen and (max-width: 500px) {
    .nav {
        display: none;
    }
    .nav-mobile {
        height: 50px;
    }

    .nav-links {
        display: none;
    }

    .nav-links.active {
        margin: 0;
        padding-right: 130px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Link {
        color: #564f48;
        text-decoration: none;
        list-style: none;
        margin: 0 20px;
    }
}
