@media only screen and (min-width: 1440px) {
    h1 {
        font-size: 55px;
        font-weight: 700;
    }

    h5 {
        font-size: 20px;
        font-weight: 100;
        padding: 5px 0;
    }

    p {
        font-size: 16px;
        padding-top: 5px;
        line-height: 28px;
        font-weight: 300;
    }

    .content {
        width: 1440px;
        display: flex;
    }

    .skills {
        font-size: 15px;
        font-weight: 200;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 120px;
        padding-top: 160px;
    }

    .about-me p {
        width: 645px;
    }

    .about-me-img {
        width: 396px;
        padding-top: 50px;
    }

    img {
        width: 100%;
    }

    .btn {
        position: absolute;
        bottom: 200px;
        left: 118px;
        width: 500px;
    }

    .btn-mobile {
        display: none;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 120px;
        padding-top: 160px;
    }

    .contact-h5 {
        padding: 25px 0;
    }

    .contact a {
        font-size: 20px;
        font-weight: 100;
        text-decoration: none;
        color: #564f48;
    }
}

@media only screen and (min-width: 1920px) {
    h1 {
        font-size: 60px;
        font-weight: 700;
    }

    h5 {
        font-size: 22px;
        font-weight: 100;
        padding: 5px 0;
    }

    p {
        font-size: 18px;
        padding-top: 5px;
        line-height: 28px;
        font-weight: 300;
    }

    .content {
        width: 1920px;
        display: flex;
    }

    .skills {
        font-size: 17px;
        font-weight: 200;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 280px;
        padding-top: 270px;
    }

    .about-me p {
        width: 645px;
    }

    .about-me-img {
        width: 528px;
        padding-top: 50px;
        padding-left: 80px;
    }

    img {
        width: 100%;
    }

    .btn {
        position: absolute;
        bottom: 240px;
        left: 275px;
        width: 500px;
    }

    .btn-mobile {
        display: none;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 12.5px 57.5px;
        margin-right: 52px;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 11.5px 62.5px;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 767px;
        padding-left: 280px;
        padding-top: 270px;
    }

    .contact-h5 {
        font-size: 22px;
        font-weight: 100;
        padding: 25px 0;
    }
}

@media only screen and (max-width: 500px) {
    h1 {
        padding-left: 16px;
        padding-top: 20px;
        font-size: 30px;
        font-weight: 700;
    }

    h5 {
        font-size: 18px;
        font-weight: 100;
        padding: 10px 16px 10px;
    }

    p {
        font-size: 16px;
        padding: 0px 16px;
        line-height: 28px;
        font-weight: 300;
    }

    .skills {
        font-size: 14px;
        font-weight: 200;
        padding-bottom: 10px;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .about-me {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 100%;
        padding-top: 40px;
        margin-bottom: 40px;
    }

    .about-me-img {
        width: 75.8%;
    }

    img {
        width: 100%;
    }

    .btn {
        display: none;
    }

    .btn-mobile {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 16px;
    }

    .btn-links {
        color: #efefef;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        background: #564f48;
        padding: 3% 8.7%;
        margin-right: 2%;
        border-radius: 20px;
    }

    .btn2-links {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        padding: 2.8% 10.4%;
        border-radius: 20px;
        border: 1px solid #564f48;
    }

    .contact {
        color: #564f48;
        font-family: "Roboto", sans-serif;
        width: 414px;
        padding-top: 40px;
        margin-bottom: 40px;
    }

    .contact-h5 {
        padding: 30px 0px 10px 20px;
    }

    .contact a {
        font-size: 20px;
        font-weight: 100;
        text-decoration: none;
        color: #564f48;
    }
}
