@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}

@media only screen and (min-width: 1440px) {
    .app {
        height: 100vh;
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}

@media only screen and (min-width: 1920px) {
    .app {
        height: 100vh;
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .app {
        width: 100%;
        background: #efefef;
        overflow: hidden;
    }

    .container {
        height: 100%;
    }
}
